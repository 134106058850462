import React from "react";
import style from "./downloadButton.module.css";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export const DownloadButton = ({ href, id, isApple, showStars = true }) => {

  const data = useStaticQuery(graphql`
  query {
    appleStoreButton: file(relativePath: { eq: "apple-store-button.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 100
          height: 30
          formats: [WEBP]
          layout: FIXED
          placeholder: BLURRED
          quality: 20
        )
      }
    }
    googlePlayButton: file(relativePath: { eq: "google-play-button.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 100
          height: 30
          formats: [WEBP]
          layout: FIXED
          placeholder: BLURRED
          quality: 20
        )
      }
    }
  }
`);

  return (
    <a
      className={style.downloadOption}
      href={href}
      id={id}
      target="_blank"
      rel="noopener noreferrer"
    >
      {isApple ?
        <GatsbyImage
          alt="go to apple store"
          image={getImage(data.appleStoreButton.childImageSharp.gatsbyImageData)}
          className={`${style.storeImage}`}
        />
        :
        <GatsbyImage
          alt="go to google play store"
          image={getImage(data.googlePlayButton.childImageSharp.gatsbyImageData)}
          className={`${style.storeImage}`}
        />}
    </a>
  );
};
