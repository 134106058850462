import React, { useEffect, useState } from "react";
import Seo from "../components/seo/seo";
import { useIntl } from "gatsby-plugin-intl";
import { Layout } from "../components/layout/layout";
import style from "../assets/css/main.module.css";
import { SsyIntersectionObserver } from "../components/ssyIntersectionObserver/ssyIntersectionObserver";
import { SsyLink } from "../components/ssyLink/ssyLink";
import { SSYSlider } from "../components/slider/slider";
import { NewSlider } from "../components/newSlider/newSlider";
import { RaisingBox } from "../components/raisingBox/raisingBox";
import axios from "axios";
import { FirstSection } from "../components/dashboardSections/firstSection/firstSection";
import { AllYouNeed } from "../components/dashboardSections/allYouNeed/allYouNeed";
import CheckMark from "../assets/images/check-mark.svg"
import '../assets/css/app.css';

const config = [
  {
    image: "slider1",
    body: "LANDING_HOW_TO_1_BODY",
    footer: "LANDING_HOW_TO_1_FOOTER",
    isReview: true
  },
  {
    image: "slider2",
    body: "LANDING_HOW_TO_2_BODY",
    footer: "LANDING_HOW_TO_2_FOOTER",
    isReview: false
  },
  {
    image: "slider3",
    body: "LANDING_HOW_TO_3_BODY",
    footer: "LANDING_HOW_TO_3_FOOTER",
    isReview: false
  },
];

const IndexPage = (props) => {
  const intl = useIntl();
  const [trackId, setTrackId] = useState(null)
  const [, setDownloadWhere] = useState({
    device: "NAVIGATOR",
  });

  const isMobile = () => typeof window !== "undefined" && window.innerWidth < 768;

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;
    let device = "NAVIGATOR";
    if (/android/i.test(userAgent)) {
      device = "ANDROID";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      device = "IOS";
    }
    setDownloadWhere({
      device,
    });

    if (document.referrer) {
      let url = `${process.env.GATSBY_API_URL}/trackablelinks/hash?referrer=`
      if (document.referrer) {
        url += document.referrer;
      }
      if (!!window.location.search) {
        url += `&${window.location.search.replace('?', '')}`
      }
      const headers = {
        'Content-Type': 'text/plain',
        'Access-Control-Allow-Origin': "*"
      };
      axios.get(url, { headers: headers }).then(res => setTrackId(res.data))
    }
  }, []);

  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: "HOME_META_TITLE" })}
        url={props.location.pathname}
        lang={intl.formatMessage({ id: "MAIN_LANGUAGE" })}
        description={intl.formatMessage({ id: "HOME_META_DESCRIPTION" })}
      />
      <main className={style.pageStyles}>
        <SsyIntersectionObserver>
          <RaisingBox>
            <FirstSection intl={intl} trackId={trackId} />
          </RaisingBox>
        </SsyIntersectionObserver>
        {<> <SsyIntersectionObserver>
          <SSYSlider
              data={config}
              autoplay={false}
              trackId={trackId}
              whiteDots={true}
              withBackground={false}
          />
        </SsyIntersectionObserver>
          <AllYouNeed intl={intl} trackId={trackId}/>
          <SsyIntersectionObserver>
            <RaisingBox>
              <section
                  className={`${style.sliderSection} ${style.section} commentsSection`}
              >
                <h2 className={style.titleSliders}>
                  {intl.formatMessage({id: "LANDING_COSTUMERS_TITLE"})}
                </h2>
                <NewSlider
                    translationPrefix="LANDING_TESTIMONIAL"
                    intl={intl}
                    data={[1, 2, 3, 4, 2]}
                />
                <NewSlider
                    translationPrefix="LANDING_TESTIMONIAL"
                    intl={intl}
                    delayed={true}
                    data={[5, 6, 7, 8, 9]}
                />
              </section>
            </RaisingBox>
          </SsyIntersectionObserver>
          <SsyIntersectionObserver>
            <section className={style.joinSection}>
              <RaisingBox>
                <div className={style.buttonContainer}>
                  <SsyLink
                      to={`${process.env.GATSBY_DASHBOARD_URL}/${intl.formatMessage(
                          {
                            id: "MAIN_LANGUAGE",
                          }
                      )}/register${trackId ? "?t=" + trackId : ""}`}
                      type="primary"
                      id="LANDING_IDEAS_BUTTON"
                      internal={false}
                      label={intl.formatMessage({
                        id: "LANDING_IDEAS_BUTTON",
                      })}
                  />
                </div>
                <div className={style.textFooterContainer}>
                  <img src={CheckMark} alt="checkmark"/>
                  <p className={style.footerText}>{intl.formatMessage({id: "LANDING_IDEAS_FOOTER"})}</p>
                </div>
              </RaisingBox>
            </section>
          </SsyIntersectionObserver>
          <SsyIntersectionObserver>
            <RaisingBox>
              <section className={style.contactSection}>
                <h2>{intl.formatMessage({id: "LANDING_QUESTIONS_TITLE"})}</h2>
                <p>{intl.formatMessage({id: "LANDING_QUESTIONS_BODY"})}</p>
                <SsyLink
                    to={`${intl.formatMessage({
                      id: "FOOTER_SUPPORT_CONTACT_LINK",
                    })}`}
                    type="primary"
                    id="LANDING_CONTACT_BUTTON"
                    internal={false}
                    label={intl.formatMessage({
                      id: "LANDING_QUESTIONS_BUTTON",
                    })}
                />
              </section>
            </RaisingBox>
          </SsyIntersectionObserver> </>}
      </main>
    </Layout>
  );
};

export default IndexPage;
