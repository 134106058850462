import { GatsbyImage } from "gatsby-plugin-image";
import style from "./smallIconAndText.module.css";
import React from "react";
import { getImage } from "gatsby-plugin-image";

export const SmallIconAndText = ({ icon, title, subtitle }) => {
    return (<div className={style.container}>
        <GatsbyImage
            alt="icon"
            image={getImage(icon)}
            className={`${style.mainImage}`}
        />
        <div className={style.textContainer}>
            <h3 className={style.title}>{title}</h3>
            <p className={style.subtitle}>{subtitle}</p>
        </div>
    </div>)
}