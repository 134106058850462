import React from "react";
import style from "./firstSection.module.css";
import './firstSection.css'
import HtmlPrinter from "../../htmlPrinter/htmlPrinter";
import { DownloadButton } from '../../downloadButton/downloadButton'
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { SsyLink } from "../../ssyLink/ssyLink";
import { SmallIconAndText } from "./smallIconAndText/smallIconAndText";

export const FirstSection = ({ intl, trackId }) => {

    const data = useStaticQuery(graphql`
    query {
      mainBannerPhones: file(relativePath: { eq: "main-banner-phones.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
            layout: CONSTRAINED
            placeholder: BLURRED
            blurredOptions: { width: 400 }
            quality: 80
            )
        }
      }
      lockIcon: file(relativePath: { eq: "lock-icon.png" }) {
        childImageSharp {
            gatsbyImageData(
                formats: [WEBP]
                layout: FIXED
                placeholder: BLURRED
                quality: 20
            )
        }
      }
      serverIcon: file(relativePath: { eq: "server-icon.png" }) {
        childImageSharp {
            gatsbyImageData(
                formats: [WEBP]
                layout: FIXED
                 placeholder: BLURRED
                quality: 20
            )
        }
      }
      starsIcon: file(relativePath: { eq: "stars-icon.png" }) {
        childImageSharp {
            gatsbyImageData(
                formats: [WEBP]
                layout: FIXED
                placeholder: BLURRED
                quality: 20
            )
        }
      }
      fiveStars: file(relativePath: { eq: "five-stars.jpg" }) {
        childImageSharp {
            gatsbyImageData(
                formats: [WEBP]
                layout: FIXED
                placeholder: BLURRED
                quality: 10
            )
        }
      }
    }
  `);

    return (
        <section className={`${style.firstSection} ${style.section}`}>
            <div className={style.topContainer}>
                <div className={style.scoreContainer}>
                    <span className={style.scoreContainerSpan}>4.6</span>
                    <div className={style.starContainer}>
                        <GatsbyImage
                            alt="5 stars image"
                            image={getImage(data.fiveStars.childImageSharp.gatsbyImageData)}
                            className={style.fiveStars}
                        />
                    </div>
                </div>
                <div className={style.storeButtons}>
                    <DownloadButton
                        id="LANDING_APP_STORE_BUTTON"
                        href={`${process.env.GATSBY_IOS_URL}${intl.formatMessage({
                            id: "MAIN_LANGUAGE",
                          })}`}
                        isApple={true}
                    />

                    <DownloadButton
                        id="LANDING_PLAY_STORE_BUTTON"
                        href={`${process.env.GATSBY_ANDROID_URL}`}
                        isApple={false}
                    />
                </div>
            </div>
            <div className={style.bottomContainer}>
                <div className={style.textContainer}>
                    <HtmlPrinter
                        content={intl.formatMessage({ id: "LANDING_TITLE" })}
                    />
                    <p className={style.subtitle}>
                        {intl.formatMessage({ id: "LANDING_SUBTITLE" })}
                    </p>
                    <div className={style.storeButtons}>
                        <DownloadButton
                            id="LANDING_APP_STORE_BUTTON"
                            href={`${process.env.GATSBY_IOS_URL}${intl.formatMessage({
                                id: "MAIN_LANGUAGE",
                            })}`}
                            isApple={true}
                        />

                        <DownloadButton
                            id="LANDING_PLAY_STORE_BUTTON"
                            href={`${process.env.GATSBY_ANDROID_URL}`}
                            isApple={false}
                        />
                    </div>
                    <SsyLink
                        to={`${process.env.GATSBY_DASHBOARD_URL}/${intl.formatMessage({
                            id: "MAIN_LANGUAGE",
                        })}/register${trackId ? "?t=" + trackId : ""}`}
                        id="LANDING_MAIN_BUTTON"
                        type="primary"
                        internal={false}
                        label={intl.formatMessage({
                            id: "LANDING_MAIN_BUTTON",
                        })}
                        className={style.mainButton}
                    />
                    <div className={style.footerContainer}>
                        <SmallIconAndText
                            icon={data.starsIcon.childImageSharp.gatsbyImageData}
                            title={intl.formatMessage({ id: "LANDING_DATA_PROTECTION_TITLE" })}
                            subtitle={intl.formatMessage({ id: "LANDING_DATA_PROTECTION_SUBTITLE" })}
                        />
                        <SmallIconAndText
                            icon={data.serverIcon.childImageSharp.gatsbyImageData}
                            title={intl.formatMessage({ id: "LANDING_EU_TITLE" })}
                            subtitle={intl.formatMessage({ id: "LANDING_EU_SUBTITLE" })}
                        />
                        <SmallIconAndText
                            icon={data.lockIcon.childImageSharp.gatsbyImageData}
                            title={intl.formatMessage({ id: "LANDING_TLS_TITLE" })}
                            subtitle={intl.formatMessage({ id: "LANDING_TLS_SUBTITLE" })}
                        />
                    </div>

                </div>
                <div className={style.imageContainer}>
                    <GatsbyImage
                        alt="main samsaidyes banner"
                        image={getImage(data.mainBannerPhones.childImageSharp.gatsbyImageData)}
                        className={`${style.mainImage} ${style.desktop}`}
                    />
                </div>
            </div>

        </section>
    );
};
