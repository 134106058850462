import React, {useEffect, useRef, useState} from "react";
import {useIntl} from "gatsby-plugin-intl";
import {graphql, useStaticQuery} from "gatsby";
import {SsyLink} from "../ssyLink/ssyLink";
import HtmlPrinter from '../htmlPrinter/htmlPrinter'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import style from "./slider.module.css";
import './slider.css'
import Checkbox from '../../assets/images/yellow-checkbox.svg'
import PlayButton from '../../assets/images/play-button.svg';
import AppleLogo from '../../assets/images/apple-logo-black.svg';

export const SSYSlider = ({
                              withBackground,
                              title,
                              trackId,
                              data,
                              customTitle,
                              customButtonLabel,
                              autoplay = true,
                              whiteDots = true,
                          }) => {
    const sliderRef = useRef(null);
    const ref = useRef(null);
    const intl = useIntl();
    const [currentSlide, setCurrentSlide] = useState(data[0]);
    const [isIosUser, setIsIosUser] = useState(false);
    const [showDemo, setShowDemo] = useState(false);

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor;
        setIsIosUser((/iPad|iPhone|iPod/.test(userAgent) || /Mac/i.test(userAgent)) && !window.MSStream);
    }, [])


    const images = useStaticQuery(graphql`
    query {
      slider1: file(relativePath: { eq: "slider-1.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 240
            formats: [WEBP]
            layout: FIXED
            placeholder: NONE
          )
        }
      }
      slider2: file(relativePath: { eq: "slider-2.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 240
            formats: [WEBP]
            layout: FIXED
            placeholder: NONE
          )
        }
      }
      slider3: file(relativePath: { eq: "slider-3.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 240
            formats: [WEBP]
            layout: FIXED
            placeholder: NONE
          )
        }
      }
      fiveStars: file(relativePath: { eq: "five-stars.jpg" }) {
        childImageSharp {
            gatsbyImageData(
                formats: [WEBP]
                layout: FIXED
                placeholder: NONE
            )
        }
      }
    }
  `);

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        className: "center",
        centerMode: true,
        centerPadding: "0",
        slidesToShow: 1,
        autoplay: false,
        arrows: false,
        slidesToScroll: 1,
        beforeChange: (_, next) => {
            setCurrentSlide(data[next]);
        }
    };

    return (
        <div className="sliderContainer">
            <h4 className={style.smallerTitle}>{intl.formatMessage({id: "LANDING_SETUP_OVERTITLE"})}</h4>
            <HtmlPrinter
                content={intl.formatMessage({id: "LANDING_SETUP_TITLE"})}
            />
            <section
                className={`${style.secondSection} ${customButtonLabel ? style.secondSectionMartha : ""} howToSection`}
                id={"sectionSlide"}
                ref={ref}
            >
                <div className={`${style.slider}`}>
                    {title && <h1>{intl.formatMessage({id: title})}</h1>}

                    <div
                        className={`${style.sliderContainer} ${whiteDots ? style.white : style.black
                        }`}
                    >
                        <Slider
                            ref={sliderRef}
                            {...settings}
                            style={{zIndex: 700}}
                        >
                            {data.map((item, i) => (
                                <article key={i} className={style.sliderArticle}>
                                    <GatsbyImage
                                        alt="app example"
                                        image={images[item.image].childImageSharp.gatsbyImageData}
                                    />

                                    <div className={style.slideNumbers}>
                                        {data.map((_, number) => (
                                            <p key={i + ' ' + number}
                                               className={`${style.slideNumber} ${number === i ? 'activeNumber' : ''}`}
                                               onClick={() => sliderRef.current.slickGoTo(number)}>
                                                {number + 1}
                                            </p>
                                        ))}
                                    </div>

                                    {item.body ? (
                                        <p className={style.slideDescription}>
                                            {intl.formatMessage({id: item.body})}
                                        </p>
                                    ) : (
                                        <div className={style.marginS}></div>
                                    )}
                                </article>
                            ))}
                        </Slider>
                    </div>
                </div>
            </section>
            <div className={style.linkDiv}>
                <SsyLink
                    to={isIosUser ? `${process.env.GATSBY_IOS_URL}${intl.formatMessage({
                            id: "MAIN_LANGUAGE",
                        })}` :
                        `${process.env.GATSBY_ANDROID_URL}`}
                    type={customButtonLabel ? "primaryMarthas" : "primary"}
                    id="LANDING_DOWNLOAD_APP_BUTTON"
                    internal={false}
                    label={customButtonLabel ? customButtonLabel : intl.formatMessage({
                        id: "FOOTER_DOWNLOAD",
                    })}
                    img={isIosUser ? AppleLogo : null}
                />


                <SsyLink
                    callback={() => {
                        setShowDemo(!showDemo);
                    }}
                    type={"primaryBlue"}
                    id="LANDING_DEMO_BUTTON"
                    internal={false}
                    img={PlayButton}
                    label={!showDemo ? intl.formatMessage({
                        id: "LANDING_DEMO_BUTTON",
                    }) : intl.formatMessage({
                        id: "LANDING_DEMO_BUTTON_CLOSE",
                    })
                    }
                />
            </div>

            {showDemo &&
                <div className={`${style.videoDiv}`}>
                    <iframe
                        src="https://player.vimeo.com/video/877097833?h=923933bb00&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=1&amp;mute=0"
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                        style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}}
                        title="Demo-Video">
                    </iframe>
                </div>}
            {currentSlide && currentSlide.isReview ?
                <div className={style.commentFooterContainer}>
                    <GatsbyImage
                        alt="5 stars image"
                        image={getImage(images.fiveStars.childImageSharp.gatsbyImageData)}
                        className={style.fiveStars}
                    />
                    <p className={style.comment}>{intl.formatMessage({id: currentSlide.footer})}</p>
                    <p className={style.creator}>Google User</p>
                </div> :
                <div className={style.textFooterContainer}>
                    <img src={Checkbox} alt="checkbox"/>
                    <p className={style.footerText}>{intl.formatMessage({id: currentSlide.footer})}</p>
                </div>
            }
        </div>
    );
};
