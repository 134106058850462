import React from "react";
import style from "./allYouNeed.module.css";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { SsyLink } from "../../ssyLink/ssyLink";

export const AllYouNeed = ({ intl, trackId }) => {
    const functionalities = [
        {
            image: "functionality1",
            mainText: "ALL_YOU_NEED_UNLIMITED_PHOTOS_TITLE",
            secondaryText: "ALL_YOU_NEED_UNLIMITED_PHOTOS_SUBTITLE"
        },
        {
            image: "functionality2",
            mainText: "ALL_YOU_NEED_INFO_TITLE",
            secondaryText: "ALL_YOU_NEED_INFO_SUBTITLE"
        },
        {
            image: "functionality3",
            mainText: "ALL_YOU_NEED_VIDEO_UPLOADS_TITLE",
            secondaryText: "ALL_YOU_NEED_VIDEO_UPLOADS_SUBTITLE"
        },
        {
            image: "functionality4",
            mainText: "ALL_YOU_NEED_GROUP_CHAT_TITLE",
            secondaryText: "ALL_YOU_NEED_GROUP_CHAT_SUBTITLE"
        },
        {
            image: "functionality5",
            mainText: "ALL_YOU_NEED_LIKES_COMMENTS_TITLE",
            secondaryText: "ALL_YOU_NEED_LIKES_COMMENTS_SUBTITLE"
        },
        {
            image: "functionality6",
            mainText: "ALL_YOU_NEED_PRIVATE_CHAT_TITLE",
            secondaryText: "ALL_YOU_NEED_PRIVATE_CHAT_SUBTITLE"
        },
        {
            image: "functionality7",
            mainText: "ALL_YOU_NEED_SLIDESHOW_TITLE",
            secondaryText: "ALL_YOU_NEED_SLIDESHOW_SUBTITLE"
        },
        {
            image: "functionality8",
            mainText: "ALL_YOU_NEED_GUESTBOOK_TITLE",
            secondaryText: "ALL_YOU_NEED_GUESTBOOK_SUBTITLE"
        }
    ]

    const data = useStaticQuery(graphql`
    query {
        functionality1: file(relativePath: { eq: "functionality-1.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
            placeholder: NONE
            layout: CONSTRAINED
            )
        }
      }
      functionality2: file(relativePath: { eq: "functionality-2.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
            placeholder: NONE
            layout: CONSTRAINED
            )
        }
      }
      functionality3: file(relativePath: { eq: "functionality-3.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
            placeholder: NONE
            layout: CONSTRAINED
            )
        }
      }
      functionality4: file(relativePath: { eq: "functionality-4.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
            placeholder: NONE
            layout: CONSTRAINED
            )
        }
      }
      functionality5: file(relativePath: { eq: "functionality-5.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
            placeholder: NONE
            layout: CONSTRAINED
            )
        }
      }
      functionality6: file(relativePath: { eq: "functionality-6.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
            placeholder: NONE
            layout: CONSTRAINED
            )
        }
      }
      functionality7: file(relativePath: { eq: "functionality-7.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
            placeholder: NONE
            layout: CONSTRAINED
            )
        }
      }
      functionality8: file(relativePath: { eq: "functionality-8.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
            placeholder: NONE
            layout: CONSTRAINED
            )
        }
      }
      googlePlay: file(relativePath: { eq: "google-play-logo.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
            placeholder: NONE
            layout: CONSTRAINED
            )
        }
      }
    }
  `);

    return (
        <section className={`${style.allYouNeed} ${style.section}`}>
            <h4 className={style.allYouNeedOverTitle}>{intl.formatMessage({ id: "ALL_YOU_NEED_OVERTITLE" })}</h4>
            <h3 className={style.allYouNeedTitle}>{intl.formatMessage({ id: "ALL_YOU_NEED_TITLE" })}</h3>
            <div className={style.functionsContainer}>

                {functionalities.map(x =>
                    <div className={style.functionality} key={x.mainText}>
                        <GatsbyImage
                            alt="functionality image"
                            image={getImage(data[x.image].childImageSharp.gatsbyImageData)}
                            className={`${style.mainImage}`}
                        />
                        <p className={style.mainText}>{intl.formatMessage({ id: x.mainText })}</p>
                        <p className={style.secondaryText}>{intl.formatMessage({ id: x.secondaryText })}</p>
                    </div>
                )}
            </div>
            <SsyLink
                to={`${process.env.GATSBY_DASHBOARD_URL}/${intl.formatMessage({
                    id: "MAIN_LANGUAGE",
                })}/register${trackId ? "?t=" + trackId : ""}`}
                id="LANDING_HOW_TO_BUTTON"
                type="primary"
                internal={false}
                label={intl.formatMessage({
                    id: "ALL_YOU_NEED_REGISTER_BUTTON",
                })}
            />
            <div className={style.allYouNeedFooterContainer}>
                <p className={style.allYouNeedFooterText}>{intl.formatMessage({ id: "ALL_YOU_NEED_GOOGLE_NOMINATED" })}</p>
                <GatsbyImage
                    alt="google play logo"
                    image={getImage(data.googlePlay.childImageSharp.gatsbyImageData)}
                    className={`${style.mainImage}`}
                />
            </div>
        </section>
    );
};
